<template>
  <div
    class="container-xs"
    style="
      height: 100%;
      display: grid;
      gap: 3rem;
      grid-template-rows: auto auto auto 1fr;
    "
  >
    <AppBackHeader to="/account-recovery">{{
      $t('account.forgot-password')
    }}</AppBackHeader>
    <p style="text-align: center; font-family: DDin">
      {{ $t('account.security-code-msg') }}
    </p>
    <form style="display: grid; gap: 0.5rem" @submit.prevent>
      <x-label tag="label" for="security-code">
        {{ $t('account.security-code') }}
      </x-label>
      <!-- TODO - required? -->
      <form-input
        id="security-code"
        v-model="token"
        v-focus
        type="text"
        required
        @valid="value => (formValid = value)"
      />
      <p
        style="
          margin-top: 1rem;
          text-align: center;
          font-size: 14px;
          color: var(--color-primary);
          text-decoration: underline;
          cursor: pointer;
        "
        @click="resend"
      >
        {{ $t('account.resend') }}
      </p>
    </form>
    <x-button
      id="validate"
      type="large"
      style="margin-top: auto"
      :disabled="!formValid"
      :is-loading="isLoading"
      @click="onValidate"
    >
      {{ $t('account.confirm-code') }}
    </x-button>
    <InfoModal
      ref="errorModal"
      title="UH OH! LOOKS LIKE SOMETHING WENT WRONG."
      :text="text"
      hide-close-button
      cta="Try Again"
      @cta="$refs.errorModal.hide()"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import InfoModal from '../../components-rf/modals/InfoModal.vue'
import AppBackHeader from '../../components-rf/AppBackHeader.vue'

export default {
  name: 'AccountRecovery',
  components: { InfoModal, AppBackHeader },
  created() {
    if (!this.email) this.$router.push('/account-recovery')
  },
  data() {
    return {
      text: '',
      isLoading: false,
      formValid: false,
    }
  },
  methods: {
    ...mapActions('RecoveryModule', ['recoverPassword', 'validateToken']),
    resend() {
      this.recoverPassword({ email: this.email }).catch(() => {
        this.text = ''
        this.$refs.errorModal.show()
      })
    },
    onValidate() {
      this.isLoading = true
      this.validateToken({ email: this.email, token: this.token })
        .then(() => this.$router.push('/account-recovery/reset'))
        .catch(() => {
          this.text = 'Seems like this code is incorrect.'
          this.$refs.errorModal.show()
        })
      this.isLoading = false
    },
  },
  computed: {
    ...mapState('RecoveryModule', ['email']),
    token: {
      get() {
        return this.$store.state.RecoveryModule.token
      },
      set(value) {
        this.$store.commit('RecoveryModule/setToken', value)
      },
    },
  },
}
</script>

<style scoped lang="scss"></style>
